<template>
    <v-main id="home">
        <v-slide-x-transition mode="out-in">
            <router-view />
        </v-slide-x-transition>
    </v-main>
</template>

<script>
export default {
    name: "CoreView",
};
</script>
<style>
</style>
